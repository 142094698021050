import React from 'react'
import PropTypes from 'prop-types'

import { colors, Rating, Text } from '../UIkit'

const Reviews = ({ feedbacks }) => {
  return (
    <div className='reviews'>
      {feedbacks.map((feedback, index) => (
        <div key={index} className='reviews__feedback'>
          <div className='reviews__feedback__info'>
            <div className='reviews__feedback__info__user'>
              <img src={feedback.customer.avatarUrl} />
              <div>
                <Text variant='body-main2'>{feedback.customer.fullName}</Text>
                <Text color={colors.gray[500]} variant='body-tertiary1'>
                  {feedback.createdAt}
                </Text>
              </div>
            </div>

            <Rating version={2} rating={feedback.rating} />
          </div>

          <Text variant='body-secondary1'>{feedback.body}</Text>
        </div>
      ))}
    </div>
  )
}

Reviews.propTypes = {
  feedbacks: PropTypes.array.isRequired
}

export default Reviews
