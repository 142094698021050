import React from 'react'
import { useTranslation } from 'react-i18next'
import { isMobile } from 'react-device-detect'

import { Star } from '../svgs'
import { colors, Text } from '../index'
import './styles.scss'

function Rating({ rating, iconSize = 14, version = 1 }) {
  const { t } = useTranslation()

  if (version === 2) return (
    <div className='rating-v2__stars'>
      {Array(5).fill(null).map((_, index) => (
        <Star
          key={index}
          color={rating < index + 1
            ? colors.gray[50]
            : colors.alerts.warning}
          width={iconSize}
          height={iconSize}
        />
      ))}
    </div>
  )

  return (
    <div className='rating'>
      {rating
        ? <>
          <Star height={16} width={16} color={colors.alerts.warning} />
          <Text variant={isMobile ? 'body-secondary1' : 'body-main1'}
                style='rating__value'>{rating.toFixed(1)}</Text>
        </>
        : <Text variant={isMobile ? 'body-secondary1' : 'body-main1'}
                style='rating__value_empty'>{t('employees:noFeedbacks')}</Text>}
    </div>
  )
}

export default Rating
