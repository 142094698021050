import React, { useCallback, useEffect, useRef } from 'react'
import { isMobile } from 'react-device-detect'

import useOnClickOutside from '../../../hooks/useOnClickOutside'
import useScrollLock from '../../../hooks/useScrollLock'
import PortalModal from './PortalModal'
import { Exit } from '../svgs'
import { Text, Loader, colors } from '../../UIkit'
import './styles.scss'

export const Modal = ({ show, handleClose, title, withoutExit, fetching, children, scrollable, mobileModal, subModal }) => {
  const modalRef = useRef()
  const { lockScroll, unlockScroll } = useScrollLock()

  const handleKeyPress = useCallback((event) => {
    if (event.key === 'Escape') handleClose()
  }, [])

  useOnClickOutside(modalRef, handleClose)

  useEffect(() => {
    if (show) {
      document.addEventListener('keydown', handleKeyPress)
      lockScroll()

      return () => {
        document.removeEventListener('keydown', handleKeyPress)
        unlockScroll()
      }
    }
  }, [handleKeyPress, show])

  const modalStyles = {
    padding: !title && (isMobile ? '0 4px' : '32px')
  }

  const exitStyles = {
    position: 'absolute',
    top: 32,
    right: title ? '24px' : '32px',
  }
  const modalContent = () => (
    <div className='modal__container' style={modalStyles} ref={modalRef}>
      {title && (
        <div className='modal__title'>
          <Text variant={isMobile ? 'subheading' : 'h4-bold'}>{title}</Text>
        </div>
      )}
      {withoutExit ? null : <Exit className='modal__close' style={exitStyles} onClick={handleClose} />}
      {title ? <div className={`modal__content ${scrollable ? 'modal-scrollable' : ''}`}>{children}</div> : children}
    </div>
  )

  const mobileContent = () => (
    <div className='mobile-modal__container' style={modalStyles} ref={modalRef}>
      <div className='mobile-modal__title'>
        {title && (
          <div >
            <Text variant='h4-bold'>{title}</Text>
          </div>
        )}
        {!withoutExit && <Exit className='mobile-modal__close' onClick={handleClose} />}
      </div>
      {title ? <div className={`mobile-modal__content ${scrollable ? 'mobile-modal-scrollable' : ''}`}>{children}</div> : children}
    </div>
  )

  const cancelButton = () => (
    <div className='mobile-modal__cancel-btn' onClick={handleClose} >
      Cancel
    </div>
  )

  return (
    <>
      {show && (!mobileModal ? (
        <PortalModal wrapperId='modal-portal'>
          <div className='modal__overlay'>
            {!fetching && modalContent()}
            {fetching && <Loader inBlock size={54} color={colors.base.white} />}
          </div>
        </PortalModal>
      ):(
        <div className={`mobile-modal__overlay ${subModal ? 'sub-modal' : ''}`}>
          {!fetching && mobileContent()}
          {withoutExit && cancelButton()}
          {fetching && <Loader inBlock size={54} color={colors.base.white} />}
        </div>))
      }
    </>
  )
}

export const RightModal = ({ show, handleClose, title, children }) => {
  const rightModalRef = useRef()
  const { lockScroll, unlockScroll } = useScrollLock()

  const handleKeyPress = useCallback((event) => {
    if (event.key === 'Escape') handleClose()
  }, [])

  useOnClickOutside(rightModalRef, handleClose)

  useEffect(() => {
    if (show) {
      document.addEventListener('keydown', handleKeyPress)
      lockScroll()

      return () => {
        document.removeEventListener('keydown', handleKeyPress)
        unlockScroll()
      }
    }
  }, [handleKeyPress, show])

  return (
    <>
      {show && (
        <PortalModal wrapperId='right-modal-portal'>
          <div className='right-modal__overlay'>
            <div className='right-modal__container' ref={rightModalRef}>
              <div className='right-modal__title'>
                <Text variant='h4-bold'>{title}</Text>
              </div>
              <Exit className='right-modal__close' onClick={handleClose} />
              {children}
            </div>
          </div>
        </PortalModal>
      )}
    </>
  )
}
