// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/fonts/Trouble-Font/Trouble-Font.ttf");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../assets/fonts/Roboto/Robotothin.ttf");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../../assets/images/landing/IntroBusiness/heading-bg.png");
var ___CSS_LOADER_URL_IMPORT_3___ = require("../../assets/images/landing/Consultation/BackgroundBig.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&family=Unbounded:wght@300;500&display=swap);"]);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
exports.push([module.id, "@font-face{font-family:Trouble Font;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}@font-face{font-family:Roboto;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");font-weight:100;font-style:normal}.landing-main{background-color:#f9f9f9;font-family:\"Montserrat\",sans-serif;font-style:normal;font-weight:normal;font-size:16px;line-height:20px;box-sizing:border-box;scroll-behavior:smooth;height:auto;display:flex;justify-content:center;align-items:center;flex-direction:column}.landing-main *:not(strong){display:flex}.landing-main section{width:100%;flex-direction:column}.landing-main section.business-section,.landing-main section.consultation-section{align-items:center;padding:0 260px 150px}.landing-main section.business-section h1,.landing-main section.consultation-section h1{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");background-repeat:no-repeat;background-size:contain;font-family:\"Unbounded\";color:#3f3947;font-weight:700;font-size:46px;line-height:60px;margin-bottom:130px;width:fit-content}.landing-main section.consultation-section h1{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") !important}", ""]);
// Exports
module.exports = exports;
