import React, { useEffect, Fragment, useMemo, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { isMobile } from 'react-device-detect'

import { Avatar, colors, Loader, Modal, Progress, Text } from '../../UIkit'
import './styles.scss'
import Feedback from '../UserFeedback/Feedback'
import { Star } from '../../UIkit/svgs'
import { useCustomerFeedbacksSelector } from '../../../store/selectors'

function CustomerFeedbacksModal({ onGetCustomerFeedbacks, customer, isShown, onClose }) {
  const { t } = useTranslation()
  const { items, fetching } = useCustomerFeedbacksSelector()

  useEffect(() => {
    if (isShown && customer) onGetCustomerFeedbacks(customer.id)
  }, [isShown])

  const feedbacks = useMemo(() => items.map((item) => (
    {
      avatarUrl: item.provider.avatarUrl,
      fullName: item.provider.fullName,
      postedAt: item.createdAt,
      rating: item.rating,
      body: item.body,
    }
  )), [items])

  const getMarkWeight = useCallback((mark, feedbacks) => (
    feedbacks.filter((f) => f.rating === mark).length / feedbacks.length
  ), [])

  const averageByMark = useMemo(() => (
    [
      { label: t('customers:stars', { count: 5 }), progress: getMarkWeight(5, feedbacks) },
      { label: t('customers:stars', { count: 4 }), progress: getMarkWeight(4, feedbacks) },
      { label: t('customers:stars', { count: 3 }), progress: getMarkWeight(3, feedbacks) },
      { label: t('customers:stars', { count: 2 }), progress: getMarkWeight(2, feedbacks) },
      { label: t('customers:stars', { count: 1 }), progress: getMarkWeight(1, feedbacks) },
    ]
  ), [feedbacks.length])

  if (fetching) return <Loader size={54} />
  if (!customer) return null

  return (
    <Modal title={isMobile ? t('customers:customerReviews') : null} show={isShown} handleClose={onClose}>
      <div className='customer-feedbacks-modal'>
        {!isMobile && (
          <>
            <Text style='customer-feedbacks-modal__title' variant='h4-bold'>{t('customers:customerReviews')}</Text>
            <div className='customer-feedbacks-modal__divider' />
          </>
        )}

        <section className='customer-feedbacks-modal__section'>
          <div className='customer-feedbacks-modal__left-column'>
            <div className='customer-feedbacks-modal__recipient'>
              <Avatar size={44} url={customer.avatarUrl} />
              <Text variant='subheading' style='customer-feedbacks-modal__recipient-name'>{customer.fullName}</Text>
            </div>

            <div className='customer-feedbacks-modal__average'>
              <div className='customer-feedbacks-modal__average-inner'>
                <Star color={colors.primary[500]} width={40} height={40} />
                <span className='customer-feedbacks-modal__average-mark'>
                  <Text variant='h1-bold'>{customer.rating.toFixed(1)}</Text>
                  <Text variant='body-main1'>&nbsp;/ 5</Text>
                </span>
              </div>

              <Text color={colors.gray[500]} variant='body-main1'>{t('customers:reviews', { count: feedbacks.length })}</Text>
            </div>

            <div className='customer-feedbacks-modal__by-mark'>
              {averageByMark.map(({ label, progress = 0 }) => (
                <Fragment key={label}>
                  <Text variant='body-main2'>{label}</Text>
                  <Progress value={progress} />
                  <Text variant='body-main2'>{Math.round(progress * 100)}%</Text>
                </Fragment>
              ))}
            </div>
          </div>

          <div className='customer-feedbacks-modal__right-column'>
            {feedbacks.map((feedback, index) => <Feedback key={index} {...feedback} />)}
          </div>
        </section>
      </div>
    </Modal>
  )
}

export default CustomerFeedbacksModal
