import { useCallback, useState } from 'react'

const useCustomersModals = (customerOrders) => {
  const { onGetCustomerOrders } = customerOrders
  const [modal, setModal] = useState(null)
  const [modalProps, setModalProps] = useState({})

  const handleCloseModal = useCallback(() => {
    setModal(false)
    setModalProps({})
  }, [])

  const handleOpenVendorFeedback = useCallback((customer) => () => {
    if (!customer.vendorFeedback) return
    setModalProps({ customer })
    setModal('vendorFeedback')
  }, [])

  const handleOpenCustomerFeedbacks = useCallback((customer) => () => {
    if (!customer.rating) return
    setModalProps({ customer })
    setModal('customerFeedbacks')
  }, [])

  const handleViewOrders = useCallback((customerId) => () => {
    onGetCustomerOrders(customerId)
    setModal('customerOrders')
  }, [])

  return {
    modal,
    modalProps,
    onViewOrders: handleViewOrders,
    onOpenVendorFeedback: handleOpenVendorFeedback,
    onOpenCustomerFeedbacks: handleOpenCustomerFeedbacks,
    onClose: handleCloseModal,
  }
}

export default useCustomersModals
