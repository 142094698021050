import React from 'react'
import cn from 'classnames'

import colors from '../colors'
import './styles.scss'

const variantsMapping = {
  'h1-bold': 'h1',
  h1: 'h1',
  'h2-bold': 'h2',
  h2: 'h2',
  'h3-medium': 'h3',
  'h3-bold': 'h3',
  h3: 'h3',
  'h4-bold': 'h4',
  h4: 'h4',
  'subheading-bold': 'h5',
  'subheading-semibold': 'h5',
  subheading: 'h5',
  'body-main1': 'p',
  'body-main2': 'p',
  'body-secondary1': 'p',
  'body-secondary2': 'p',
  'body-tertiary1': 'p',
  'body-tertiary2': 'p',
  'label-regular': 'p',
  'label-medium': 'p',
  'button-large': 'p',
  'button-small': 'p',
}

const Text = ({ variant, shouldBeUppercase = false, wrapper, style = '', color = colors.gray[800], children, ...props }) => {
  const Component = wrapper ? 'div' : variant ? variantsMapping[variant] : 'p'

  return (
    <Component
      style={{ color: color, textTransform: shouldBeUppercase && 'uppercase' }}
      className={cn(style, {
        [`text--variant-${variant}`]: variant,
      })}
      {...props}
    >
      {children}
    </Component>
  )
}

export default React.memo(Text)
