import { takeLatest, call, put } from 'redux-saga/effects'

import Api from '../../lib/api'
import * as actions from './actions'
import { toCamelCase } from '../../lib/converter'

export default function* watcherSaga() {
  yield takeLatest(actions.CUSTOMER_ORDERS_GET_REQUEST, watchResourcesGetRequest)
}

function* watchResourcesGetRequest({ payload }) {
  try {
    const { customerId } = payload
    const response = yield call(Api.CustomerOrders.get, customerId)
    yield put(actions.customerOrdersGetSuccess(toCamelCase(response.data)))
  } catch (error) {
    yield put(actions.customerOrdersGetFailure(error))
  }
}
