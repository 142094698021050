import { fork, all } from 'redux-saga/effects'
import authenticationSaga from './authentication/sagas'
import adminSaga from './admin/sagas'
import networksSaga from './employees/sagas'
import businessesSaga from './networks/sagas'
import managersSaga from './managers/sagas'
import vendorsSaga from './vendors/sagas'
import typesSaga from './types/sagas'
import categoriesSaga from './categories/sagas'
import productsSaga from './products/sagas'
import supplementsSaga from './supplements/sagas'
import passwordResetSaga from './passwordReset/sagas'
import emailConfirmationSaga from './emailConfirmation/sagas'
import usersSaga from './users/sagas'
import contactUsSaga from './contactUs/sagas'
import ordersSaga from './orders/sagas'
import notificationsSaga from './notifications/sagas'
import subscriptionsSaga from './subscriptions/sagas'
import statistics from './statistics/sagas'
import bankAccounts from './bankAccounts/sagas'
import profiles from './profiles/sagas'
import withdrawals from './withdrawals/sagas'
import staffAuthenticationSaga from './staff/authentication/sagas'
import staffOwnersSaga from './staff/owners/sagas'
import staffNetworksSaga from './staff/networks/sagas'
import staffBaristasSaga from './staff/baristas/sagas'
import staffVendorsSaga from './staff/vendors/sagas'
import staffVendorProductsSaga from './staff/vendors/products/sagas'
import staffVendorSupplementsSaga from './staff/vendors/supplements/sagas'
import staffVendorOrdersSaga from './staff/vendors/orders/sagas'
import checkCredentialsSaga from './checkCredentials/sagas'
import liqpayAccountsSaga from './liqpayAccounts/sagas'
import consultationsSaga from './consultations/sagas'
import customerSaga from './customers/sagas'
import customerFeedbacksSaga from './customerFeedbacks/sagas'
import customerOrdersSaga from './customerOrders/sagas'

export function* rootWatcherSaga() {
  yield all([
    fork(authenticationSaga),
    fork(adminSaga),
    fork(networksSaga),
    fork(businessesSaga),
    fork(managersSaga),
    fork(vendorsSaga),
    fork(typesSaga),
    fork(categoriesSaga),
    fork(productsSaga),
    fork(supplementsSaga),
    fork(passwordResetSaga),
    fork(emailConfirmationSaga),
    fork(usersSaga),
    fork(contactUsSaga),
    fork(ordersSaga),
    fork(notificationsSaga),
    fork(subscriptionsSaga),
    fork(statistics),
    fork(bankAccounts),
    fork(profiles),
    fork(withdrawals),
    fork(staffAuthenticationSaga),
    fork(staffOwnersSaga),
    fork(staffNetworksSaga),
    fork(staffBaristasSaga),
    fork(staffVendorsSaga),
    fork(staffVendorProductsSaga),
    fork(staffVendorSupplementsSaga),
    fork(staffVendorOrdersSaga),
    fork(checkCredentialsSaga),
    fork(liqpayAccountsSaga),
    fork(customerSaga),
    fork(customerFeedbacksSaga),
    fork(customerOrdersSaga),
    fork(consultationsSaga)
  ])
}
