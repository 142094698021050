import { combineReducers } from 'redux'

import admin from './admin/reducer'
import authentication from './authentication/reducer'
import bankAccounts from './bankAccounts/reducer'
import categories from './categories/reducer'
import contactUs from './contactUs/reducer'
import emailConfirmation from './emailConfirmation/reducer'
import employees from './employees/reducer'
import managers from './managers/reducer'
import networks from './networks/reducer'
import notifications from './notifications/reducer'
import orders from './orders/reducer'
import products from './products/reducer'
import profiles from './profiles/reducer'
import statistics from './statistics/reducer'
import subscriptions from './subscriptions/reducer'
import supplements from './supplements/reducer'
import types from './types/reducer'
import users from './users/reducer'
import vendors from './vendors/reducer'
import withdrawals from './withdrawals/reducer'
import staffAuthentication from './staff/authentication/reducer'
import staffOwners from './staff/owners/reducer'
import staffNetworks from './staff/networks/reducer'
import staffBaristas from './staff/baristas/reducer'
import staffVendorProducts from './staff/vendors/products/reducer'
import staffVendorSupplements from './staff/vendors/supplements/reducer'
import staffVendorOrders from './staff/vendors/orders/reducer'
import staffVendors from './staff/vendors/reducer'
import checkCredentials from './checkCredentials/reducer'
import liqpayAccounts from './liqpayAccounts/reducer'
import consultations from './consultations/reducer'
import customers from './customers/reducer'
import customerFeedbacks from '././customerFeedbacks/reducer'
import customerOrders from '././customerOrders/reducer'

export default combineReducers({
  admin,
  authentication,
  bankAccounts,
  categories,
  checkCredentials,
  contactUs,
  emailConfirmation,
  employees,
  liqpayAccounts,
  managers,
  networks,
  notifications,
  orders,
  products,
  profiles,
  statistics,
  subscriptions,
  supplements,
  types,
  users,
  vendors,
  withdrawals,
  staffAuthentication,
  staffOwners,
  staffNetworks,
  staffBaristas,
  staffVendors,
  staffVendorProducts,
  staffVendorSupplements,
  staffVendorOrders,
  consultations,
  customers,
  customerFeedbacks,
  customerOrders,
})
