import React from 'react'
import { useTranslation } from 'react-i18next'
import { Table, TableHead, TableBody, TableHeader, TableRow } from '../../UIkit/Table'
import Text from '../../UIkit/Text'
import OrdersElement from './OrdersElement'
import { isMobile } from 'react-device-detect'

const OrdersPage = ({ orders }) => {
  const { t } = useTranslation()

  const headers = [
    t('orders:orderId'),
    t('orders:date'),
    t('orders:vendor'),
    t('orders:barista'),
    t('orders:payment'),
    t('orders:status'),
  ]

  const desktopHeaders = [
    t('orders:date'),
    t('orders:vendor'),
    t('orders:barista'),
    t('orders:payment'),
  ]

  const mobileHeaders = headers.filter(header => !desktopHeaders.includes(header))

  return (
    <>
      <Table className='orders__table'>
        <TableHead>
          <TableRow>
            {isMobile &&
              <TableHeader>
                <Text variant='subheading'></Text>
              </TableHeader>
            }
            {(isMobile ? mobileHeaders : headers).map((header) => (
              <TableHeader key={header}>
                <Text variant='subheading'>{header}</Text>
              </TableHeader>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {orders.map((order) => (
            <OrdersElement key={order.id} order={order} headers={headers} />
          ))}
        </TableBody>
      </Table>
    </>
  )
}

export default OrdersPage
