export const CUSTOMER_ORDERS_GET_REQUEST = 'CUSTOMER_ORDERS_GET_REQUEST'
export const CUSTOMER_ORDERS_GET_SUCCESS = 'CUSTOMER_ORDERS_GET_SUCCESS'
export const CUSTOMER_ORDERS_GET_FAILURE = 'CUSTOMER_ORDERS_GET_FAILURE'

export const customerOrdersGetRequest = (payload) => ({
  type: CUSTOMER_ORDERS_GET_REQUEST,
  payload,
})

export const customerOrdersGetSuccess = (payload) => ({
  type: CUSTOMER_ORDERS_GET_SUCCESS,
  payload,
})

export const customerOrdersGetFailure = (error) => ({
  type: CUSTOMER_ORDERS_GET_FAILURE,
  error,
})
