import { useState, useCallback } from 'react'

import { useRowsPerPage } from '../../contexts/perPageContext'
import { useCustomersSelector } from '../../store/selectors'

const useCustomersPagination = (onGetCustomers) => {
  const [page, setPage] = useState(1)
  const { items: customers, pages } = useCustomersSelector()
  const { rowsPerPage, setRowsPerPage } = useRowsPerPage()

  const handleChangePage = useCallback((page) => {
    if (page > pages) return
    setPage(page)
    if (page > 0) onGetCustomers(page, rowsPerPage)
  }, [pages, rowsPerPage])

  const pagination = {
    activePage: page,
    perPage: rowsPerPage,
    handleChangePage,
    handleChangePerPage: setRowsPerPage,
    totalPages: pages,
    isShow: customers.length > 0,
  }

  return pagination
}

export default useCustomersPagination
