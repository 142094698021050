import React from 'react'

import './styles.scss'

const Progress = ({ value }) => {
  return (
    <div className='progress'>
      <div className='progress__scale' style={{ width: `${value * 100}%` }} />
    </div>
  )
}

export default Progress
