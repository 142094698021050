import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { useCustomerOrdersSelector, useOrdersSelector } from '../../store/selectors'
import { customerOrdersGetRequest } from '../../store/customerOrders/actions'
import { orderGetRequest } from '../../store/orders/actions'

const getIsPrevOrder = (orderIndex) => orderIndex > 0
const getIsNextOrder = (orderIndex, orders) => orderIndex < orders.length - 1

const useCustomerOrders = () => {
  const dispatch = useDispatch()
  const { items: orders, fetching: ordersFetching } = useCustomerOrdersSelector()
  const { item: order } = useOrdersSelector()
  const [orderIndex, setOrderIndex] = useState(0)

  const handleGetCustomerOrders = useCallback((customerId) => {
    dispatch(customerOrdersGetRequest({ customerId }))
  }, [])

  const handleGetOrder = useCallback((networkId, id) => {
    dispatch(orderGetRequest({ networkId, id }))
  }, [])

  const handlePrevOrder = useCallback(() => setOrderIndex((prev) => {
    if (getIsPrevOrder(prev)) return prev - 1
    return prev
  }), [])

  const handleNextOrder = useCallback(() => setOrderIndex((prev) => {
    if (getIsNextOrder(prev, orders)) return prev + 1
    return prev
  }), [orders.length])

  useEffect(() => {
    const order = orders[orderIndex]
    if (order) handleGetOrder(orders[orderIndex].networkId, order.id)
  }, [orders, orderIndex])

  useEffect(() => {
    if (!ordersFetching) setOrderIndex(0)
  }, [ordersFetching])

  return {
    orderIndex,
    onPrevOrder: handlePrevOrder,
    onNextOrder: handleNextOrder,
    isPrevOrder: getIsPrevOrder(orderIndex),
    isNextOrder: getIsNextOrder(orderIndex, orders),
    onGetCustomerOrders: handleGetCustomerOrders,
    orderFetched: Object.keys(order).length > 0,
    order,
  }
}

export default useCustomerOrders
