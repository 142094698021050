import { takeLatest, call, put } from 'redux-saga/effects'

import Api from '../../lib/api'
import * as actions from './actions'
import { toCamelCase } from '../../lib/converter'

export default function* watcherSaga() {
  yield takeLatest(actions.CUSTOMERS_GET_REQUEST, watchResourcesGetRequest)
}

function* watchResourcesGetRequest({ payload }) {
  try {
    const { per, page } = payload
    const response = yield call(Api.Customers.get, { per, page })
    yield put(actions.customersGetSuccess(toCamelCase(response.data)))
  } catch (error) {
    yield put(actions.customersGetFailure(error))
  }
}
