import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { isMobile } from 'react-device-detect'

import { Arrow, ArrowToRight, Check, Clock, Exit } from '../../UIkit/svgs'
import { TableRow, TableCell } from '../../UIkit/Table'
import { colors, Text } from '../../UIkit'
import OrderContainer from '../../../containers/Orders/OrderContainer'
import OrderStatus from '../../OrderStatus/OrderStatus'
import { ExpandedItem, ExpandedTableRow } from '../../Dashboard/Table'
import { renderTableCellWithText } from '../../../lib/renderTableCell'

const OrdersElement = ({ order, key, headers, networkId }) => {
  const { t } = useTranslation()

  const [showOrderInfo, setShowOrderInfo] = useState(false)

  const handleCloseModal = () => {
    setShowOrderInfo(false)
  }

  const [expandedRow, setExpandedRow] = useState(null)

  const handleExpandRow = (event, index) => {
    event.stopPropagation()
    if (index === expandedRow) {
      setExpandedRow(null)
    } else {
      setExpandedRow(index)
    }
  }

  const handleShowWithdrawalInfo = () => {
    if (withdrawal.status === 'pending' || withdrawal.status === 'sent') {
      handleOpen()
    } else {
      setShowWithdrawal(withdrawal)
    }
  }

  const renderOrderStatus = (status) => {
    switch (status) {
      case 'finished':
        return (
          <OrderStatus
            statusIcon={<Check width='20' height='20' color={colors.gray[500]} />}
            className={'orders__status finished'}
            textColor={colors.gray[500]}
            text={t('orders:finished')}
          />
        )
      case 'canceled':
        return (
          <OrderStatus
            statusIcon={<Exit width='20' height='20' color={colors.primary[500]} />}
            className={'orders__status canceled'}
            textColor={colors.primary[500]}
            text={t('orders:canceled')}
          />
        )
      case 'new':
        return (
          <OrderStatus
            statusIcon={<ArrowToRight width='20' height='14' color={colors.secondary[500]} />}
            className={'orders__status new'}
            textColor={colors.secondary[500]}
            text={t('orders:new')}
          />
        )
      case 'in_progress':
        return (
          <OrderStatus
            statusIcon={<Clock width='20' height='20' color={colors.secondary[500]} />}
            className={'orders__status in_progress'}
            textColor={colors.secondary[500]}
            text={t('orders:in_progress')}
          />
        )
      case 'ready':
        return (
          <OrderStatus
            statusIcon={<Check width='20' height='20' color={colors.secondary[500]} />}
            className={'orders__status ready'}
            textColor={colors.secondary[500]}
            text={t('orders:ready')}
          />
        )
      case 'failed':
        return (
          <OrderStatus
            statusIcon={<Exit width='20' height='20' color={colors.primary[500]} />}
            className={'orders__status failed'}
            textColor={colors.primary[500]}
            text={t('orders:failed')}
          />
        )
      default:
        return null
    }
  }

  return (
    <>
      <TableRow onClick={handleShowWithdrawalInfo}>
        {isMobile &&
        <TableCell onClick={(e) => handleExpandRow(e, key)}>
          {expandedRow !== key ? <Arrow /> : <div style={{ transform: 'rotate(180deg)', width: '20px' }}><Arrow /></div>}
        </TableCell>
        }
        <TableCell>
          <div className='orders__order-id'>
            <Text variant='body-main2' color={colors.secondary[500]} onClick={() => setShowOrderInfo(true)}>
              #{order.id}
            </Text>
          </div>
        </TableCell>
        {!isMobile &&
          <>
            {renderTableCellWithText(order.date)}
            {renderTableCellWithText(order.vendorFullAddress)}
            <TableCell>
              <div className='orders__personal-info'>
                <div
                  className='orders__avatar'
                  style={{
                    backgroundImage: `url(${order.barista?.avatarUrl})`,
                  }}
                ></div>
                <Text variant='body-main1'>{order.barista?.fullName}</Text>
              </div>
            </TableCell>
            {renderTableCellWithText(`${t('orders:uah')} ${order.total}`)}
          </>
        }
        <TableCell>{renderOrderStatus(order.status)}</TableCell>
      </TableRow>
      <ExpandedTableRow expended={key === expandedRow} length={headers.length}>
        <div className='custom-table__expanded-body'>
          <div className='custom-table__expanded-titles'>
            <ExpandedItem title={t('orders:date')} text={order.date} />
            <ExpandedItem title={t('orders:vendor')} text={order.vendorFullAddress} />
            <ExpandedItem title={t('orders:barista')} text={order.barista?.fullName} />
            <ExpandedItem title={t('orders:payment')} text={`${t('orders:uah')} ${order.total}`} />
          </div>
        </div>
      </ExpandedTableRow>
      {showOrderInfo && (
        <OrderContainer
          id={order.id}
          isShown={showOrderInfo}
          closeModal={handleCloseModal}
          networkId={networkId} />
      )
      }
    </>
  )
}

export default React.memo(OrdersElement)
