import * as actions from './actions'

const initialState = {
  items: [],
  fetching: false,
  pages: undefined,
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case actions.CUSTOMERS_GET_REQUEST:
      return { ...state, fetching: true }
    case actions.CUSTOMERS_GET_SUCCESS:
      return { ...state, fetching: false, items: payload, pages: payload.length ? payload[0].pages : 0, }
    case actions.CUSTOMERS_GET_FAILURE:
      return { ...state, fetching: false }

    default:
      return state
  }
}
