import { useSelector } from 'react-redux'

const customersSelector = (state) => state.customers
const customerFeedbacksSelector = (state) => state.customerFeedbacks
const customerOrdersSelector = (state) => state.customerOrders
const ordersSelector = (state) => state.orders

export const useCustomersSelector = () => useSelector(customersSelector)
export const useCustomerFeedbacksSelector = () => useSelector(customerFeedbacksSelector)
export const useCustomerOrdersSelector = () => useSelector(customerOrdersSelector)
export const useOrdersSelector = () => useSelector(ordersSelector)
