import * as actions from './actions'

const initialState = {
  items: [],
  fetching: false,
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case actions.CUSTOMER_ORDERS_GET_REQUEST:
      return { ...state, fetching: true }
    case actions.CUSTOMER_ORDERS_GET_SUCCESS:
      return { ...state, fetching: false, items: payload }
    case actions.CUSTOMER_ORDERS_GET_FAILURE:
      return { ...state, fetching: false }

    default:
      return state
  }
}
