import React from 'react'
import { useTranslation } from 'react-i18next'
import { isMobile } from 'react-device-detect'

import { EmptyPageMessage, Loader, Table, TableHead, TableHeader, TableRow, Text } from '../UIkit'
import CustomersList from './CustomersList'
import { useCustomersSelector } from '../../store/selectors'

const CustomersSection = ({ onOpenCustomerFeedbacks, onOpenVendorFeedback, onViewOrders }) => {
  const { t } = useTranslation()
  const { items: customers, fetching } = useCustomersSelector()

  const mobileHeaders = [{ label: t('customers:guest') }, { label: t('customers:viewOrders'), colWidth: 140 }]
  const desktopHeaders = [
    { label: t('customers:guest') },
    { label: t('customers:rating') },
    { label: t('customers:review') },
    { label: t('customers:orderCount') },
    { label: t('customers:viewOrders'), colWidth: 140 },
  ]
  const headers = isMobile ? mobileHeaders : desktopHeaders

  if (fetching) return <Loader size={54} />
  if (customers.length === 0) return <EmptyPageMessage text={t('customers:empty')} />

  return (
    <Table className='customers__table'>
      <TableHead>
        <TableRow>
          {isMobile && <TableHeader width={50} />}

          {headers.map((header) => (
            <TableHeader key={header.label} width={header.colWidth}>
              <Text variant='subheading'>{header.label}</Text>
            </TableHeader>
          ))}
        </TableRow>
      </TableHead>

      <CustomersList customers={customers} onOpenVendorFeedback={onOpenVendorFeedback}
                     onOpenCustomerFeedbacks={onOpenCustomerFeedbacks} onViewOrders={onViewOrders} />
    </Table>
  )
}

export default CustomersSection
