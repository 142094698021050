import React, { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { customersGetRequest } from '../../store/customers/actions'
import useCustomersPagination from './useCustomersPagination'
import CustomersPage from '../../components/Customers/CustomersPage'
import useCustomersModals from './useCustomersModals'
import { customerFeedbacksGetRequest } from '../../store/./customerFeedbacks/actions'
import useCustomerOrders from './useCustomerOrders'

function CustomersContainer() {
  const dispatch = useDispatch()
  const customerOrders = useCustomerOrders()

  const handleGetCustomerFeedbacks = useCallback((customerId) => dispatch(customerFeedbacksGetRequest({ customerId })), [])
  const handleGetCustomers = useCallback((page, per) => dispatch(customersGetRequest({ page, per })), [])

  const pagination = useCustomersPagination(handleGetCustomers)
  const modals = useCustomersModals(customerOrders)

  useEffect(() => {
    handleGetCustomers(1, pagination.perPage)
  }, [pagination.perPage])

  return <CustomersPage onGetCustomerFeedbacks={handleGetCustomerFeedbacks} pagination={pagination} modals={modals}
                        customerOrders={customerOrders} />
}

export default CustomersContainer
