import React from 'react'

import './styles.scss'

const Avatar = ({ url, size = 32 }) => {
  return (
    <div
      className='avatar'
      src={url}
      style={{
        backgroundImage: `url(${url})`,
        width: `${size}px`,
        minWidth: `${size}px`,
        height: `${size}px`,
        minHeight: `${size}px`,
      }} />
  )
}

export default Avatar
