import React from 'react'
import moment from 'moment'

import { Avatar, Rating, Text } from '../../UIkit'
import './styles.scss'

function Feedback({ avatarUrl, fullName, createdAt, rating, body }) {
  return (
    <div>
      <div className='feedback__user'>
        <Avatar size={44} url={avatarUrl} />
        <div>
          <Text variant='body-main2' style='feedback__name'>{fullName}</Text>
          <Text variant='body-secondary1'
                style='feedback__posted-at'>{moment(createdAt).format('DD-MM-YYYY')}</Text>
        </div>

        <div className='feedback__rating'>
          <Rating iconSize={18} version={2} rating={rating} />
        </div>
      </div>

      <Text variant='body-secondary1'>{body}</Text>
    </div>
  )
}

export default Feedback
