import React from 'react'
import { useTranslation } from 'react-i18next'

import { Modal, Text } from '../../UIkit'
import Feedback from '../UserFeedback/Feedback'
import './styles.scss'

function VendorFeedbackModal({ customer, isShown, onClose }) {
  const { t } = useTranslation()

  if (!customer) return null

  return (
    <Modal show={isShown} handleClose={onClose}>
      <div className='vendor-feedback-modal'>
        <Text style='vendor-feedback-modal__title' variant='h4-bold'>{t('customers:review')}</Text>
        <div className='vendor-feedback-modal__divider' />

        <Feedback avatarUrl={customer.avatarUrl} fullName={customer.fullName} postedAt={customer.postedAt}
                  rating={customer.vendorRating} body={customer.vendorFeedback} />
      </div>
    </Modal>
  )
}

export default VendorFeedbackModal
