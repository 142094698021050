import React, { useState, useCallback, Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { isMobile } from 'react-device-detect'

import { Avatar, Button, colors, Rating, TableBody, TableCell, TableRow, Text } from '../UIkit'
import capitalize from '../../lib/capitalize'
import { Arrow, EyeIcon } from '../UIkit/svgs'
import './styles.scss'
import { ExpandedTableRow } from '../Dashboard/Table'

const CustomersList = ({ customers, onOpenVendorFeedback, onOpenCustomerFeedbacks, onViewOrders }) => {
  const { t } = useTranslation()
  const [expandedRow, setExpandedRow] = useState(null)

  const handleExpandRow = useCallback((index) => () => {
    setExpandedRow((prevRow) => index === prevRow ? null : index)
  }, [])

  if (isMobile) return (
    <TableBody>
      {customers.map((customer, index) => (
        <Fragment key={customer.id}>
          <TableRow>
            <TableCell onClick={handleExpandRow(index)}>
              {expandedRow !== index ? <Arrow /> : <div className='employees__rotated-arrow'><Arrow /></div>}
            </TableCell>

            <TableCell className='customers__guest-cell'>
              <Avatar url={customer.avatarUrl} />
              <Text variant='body-main1'>{capitalize(customer.fullName)}</Text>
            </TableCell>

            <TableCell>
              <Button variant='semantic-wrapper' className='customers__view-btn' onClick={onViewOrders(customer.id)}>
                <EyeIcon width={24} height={24} color={colors.gray[800]} />
                <Text variant='body-secondary2'>{t('customers:view')}</Text>
              </Button>
            </TableCell>
          </TableRow>

          <ExpandedTableRow expended={index === expandedRow} length={3}>
            <div className='customers-expanded-items'>
              <div className='customers-expanded-item'>
                <Text variant='body-secondary2'>{t('customers:rating')}</Text>

                <Button variant='semantic-wrapper' className='customers__view-btn' onClick={onOpenCustomerFeedbacks(customer)}>
                  <Rating rating={customer.rating} />
                </Button>
              </div>

              <div className='customers-expanded-item'>
                <Text variant='body-secondary2'>{t('customers:review')}</Text>

                <Button variant='semantic-wrapper' className='customers__vendor-rating customers__view-btn'
                        onClick={onOpenVendorFeedback(customer)}>
                  <Rating rating={customer.vendorRating} />
                  <Text variant='body-secondary1' style='customers__vendor-feedback'>{customer.vendorFeedback}</Text>
                </Button>
              </div>
            </div>
          </ExpandedTableRow>
        </Fragment>
      ))}
    </TableBody>
  )

  return (
    <TableBody>
      {customers.map((customer) => (
        <TableRow key={customer.id}>
          <TableCell className='customers__guest-cell'>
            <Avatar url={customer.avatarUrl} />
            <Text variant='body-main1'>{capitalize(customer.fullName)}</Text>
          </TableCell>

          <TableCell>
            <Button variant='semantic-wrapper' onClick={onOpenCustomerFeedbacks(customer)}>
              <div className='customers__view-btn'>
                <Rating rating={customer.rating} />
              </div>
            </Button>
          </TableCell>

          <TableCell className='customers__vendor-col'>
            <Button variant='semantic-wrapper' className='customers__vendor-rating customers__view-btn'
                    onClick={onOpenVendorFeedback(customer)}>
              <Rating rating={customer.vendorRating} />
              <Text style='customers__vendor-feedback' variant='body-main1'>{customer.vendorFeedback}</Text>
            </Button>
          </TableCell>

          <TableCell>
            <Text className='customers__order-count' variant='body-main1'>{customer.ordersCount}</Text>
          </TableCell>

          <TableCell>
            <Button variant='semantic-wrapper' className='customers__view-btn' onClick={onViewOrders(customer.id)}>
              <EyeIcon width={24} height={24} color={colors.gray[800]} />
              <Text variant='body-main2'>{t('customers:view')}</Text>
            </Button>
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  )
}

export default CustomersList
