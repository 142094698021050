/* eslint-disable max-len */

import requestManager from './requestManager'

export default {
  Staff: {
    Session: {
      create: (params) => requestManager.post('/api/v2/staff/auth', params),
      delete: () => requestManager.delete('/api/v2/staff/auth'),
    },
    Owners: {
      fetch: (params) => requestManager.get('/api/v2/staff/owners', params),
    },
    Networks: {
      fetch: (params) => requestManager.get('/api/v2/staff/networks', params),
      get: (id) => requestManager.get(`/api/v2/staff/networks/${id}`),
      update: (id, params) => requestManager.put(`/api/v2/staff/networks/${id}`, params)
    },
    Vendors: {
      Products: {
        fetch: (params) => requestManager.get(`/api/v2/staff/vendors/${params.vendorId}/products`, params.pagyParams)
      },
      Supplements: {
        fetch: (params) => requestManager.get(`/api/v2/staff/vendors/${params.vendorId}/supplements`, params.pagyParams)
      },
      Orders: {
        fetch: (params) => requestManager.get(`/api/v2/staff/vendors/${params.vendorId}/orders`, params.pagyParams)
      },
      get: (id) => requestManager.get(`/api/v2/staff/vendors/${id}`)
    },
    Baristas: {
      fetch: (vendorId) => requestManager.get(`/api/v2/staff/vendors/${vendorId}/baristas`)
    }
  },
  Consultation: {
    create: (params) => requestManager.post('/api/v2/owner/consultation', params),
  },
  LiqpayAccounts: {
    get: () => requestManager.get('/api/v2/owner/network/liqpay_account'),
    create: (params) => requestManager.post('/api/v2/owner/network/liqpay_accounts', params),
  },
  Credentials: {
    get: (params) => requestManager.get('/api/v2/owner/check_credentials', params)
  },
  Session: {
    create: (params) => requestManager.post('/api/v2/owner/auth', params),
    createPhone: (params) => requestManager.post('/api/v2/owner/phone_authentications', params),
    delete: () => requestManager.delete('/api/v2/owner/auth'),
    restore: () => requestManager.get('/api/v2/owner/auth'),
  },
  Admin: {
    update: (params) => requestManager.put('/api/v2/owner/profile', params),
    delete: () => requestManager.delete('/api/v2/owner/profile')
  },
  Newsletter: {
    create: (params) => requestManager.post('/api/admin/subscribers', params),
    get: (params) => requestManager.get('/api/admin/subscribers', params),
  },
  NewsletterMail: {
    create: (params) => requestManager.post('/api/admin/news_letters', params),
  },
  Baristas: {
    get: (params) => requestManager.get('/api/v2/owner/network/baristas', params),
  },
  Poster: {
    create: (params) => requestManager.post('/api/v2/owner/poster', params),
    delete: () => requestManager.delete('/api/v2/owner/poster'),
  },
  ProductTypes: {
    fetch: (networkId) => requestManager.get(`/api/v2/owner/networks/${networkId}/product_types`, {}),
    get: (networkId, slug) => requestManager.get(`/api/v2/owner/networks/${networkId}/product_types/${slug}`, {}),
  },
  ProductCategories: {
    fetch: (networkId, params) => requestManager.get(`/api/v2/owner/networks/${networkId}/product_categories`, params),
  },
  Categories: {
    fetch: (networkId, params) => requestManager.get(`/api/v2/owner/networks/${networkId}/categories`, params),
    add: (networkId, id) => requestManager.post(`/api/v2/owner/networks/${networkId}/categories`, { id: id }),
    remove: (networkId, id) => requestManager.delete(`/api/v2/owner/networks/${networkId}/categories/${id}`),
  },
  Products: {
    fetch: (networkId, params) => requestManager.get(`/api/v2/owner/networks/${networkId}/products`, params),
    create: (networkId, params) => requestManager.post(`/api/v2/owner/networks/${networkId}/products`, params),
    update: (networkId, id, params) => requestManager.put(`/api/v2/owner/networks/${networkId}/products/${id}`, params),
    delete: (networkId, id) => requestManager.delete(`/api/v2/owner/networks/${networkId}/products/${id}`),
  },
  Supplements: {
    fetch: (networkId, params) => requestManager.get(`/api/v2/owner/networks/${networkId}/supplements`, params),
    create: (networkId, params) => requestManager.post(`/api/v2/owner/networks/${networkId}/supplements`, params),
    update: (networkId, id, params) => requestManager.put(`/api/v2/owner/networks/${networkId}/supplements/${id}`, params),
    delete: (networkId, id) => requestManager.delete(`/api/v2/owner/networks/${networkId}/supplements/${id}`),
  },
  Barista: {
    get: (id) => requestManager.get(`/api/v2/owner/network/baristas/${id}`),
    create: (params) => requestManager.post('/api/v2/owner/network/baristas', params),
    update: (id, params) => requestManager.put(`/api/v2/owner/network/baristas/${id}`, params),
    delete: (id) => requestManager.delete(`/api/v2/owner/network/baristas/${id}`),
  },

  Network: {
    create: (params) => requestManager.post('/api/v2/owner/networks', params),
    get: (id) => requestManager.get(`/api/v2/owner/networks/${id}`),
    update: (params) => requestManager.put(`/api/v2/owner/networks/${params.id}`, params),
  },
  NetworkActivation: {
    get: () => requestManager.get('/api/v2/owner/network/activation')
  },
  Managers: {
    get: (id) => requestManager.get(`/api/v2/businesses/${id}/managers`),
  },
  Manager: {
    get: (id) => requestManager.get(`/api/v2/businesses/managers/${id}`),
    update: (params) => requestManager.put(`/api/v2/businesses/managers/${params.id}`, params),
    create: (params) => requestManager.post('/api/v2/businessesmanagers/', params),
    delete: (id) => requestManager.delete(`/api/v2/businesses/managers/${id}`),
  },
  Vendor: {
    create: (networkId, params) => requestManager.post(`/api/v2/owner/networks/${networkId}/vendors`, params),
    delete: (networkId, id) => requestManager.delete(`/api/v2/owner/networks/${networkId}/vendors/${id}`),
    get: (networkId, id) => requestManager.get(`/api/v2/owner/networks/${networkId}/vendors/${id}`),
    update: (networkId, id, params) => requestManager.put(`/api/v2/owner/networks/${networkId}/vendors/${id}`, params),

    Rating: {
      get: (networkId, id) => requestManager.get(`/api/v2/owner/networks/${networkId}/vendors/${id}/rating`),
    },

    Feedbacks: {
      get: (networkId, id) => requestManager.get(`/api/v2/owner/networks/${networkId}/vendors/${id}/feedbacks`),
    },
  },

  Vendors: {
    get: (networkId, params) => requestManager.get(`/api/v2/owner/networks/${networkId}/vendors`, params),
  },
  VendorsCities: {
    get: (networkId) => requestManager.get(`/api/v2/owner/networks/${networkId}/cities`),
  },
  Product: {
    get: (params) => requestManager.get('/api/admin/products', params),
    create: (params) => requestManager.post('/api/admin/products', params),
    delete: (id) => requestManager.delete(`/api/admin/products/${id}`),
    update: (params) => requestManager.put(`/api/admin/products/${params.id}`, params),
  },
  Posts: {
    get: (params) => requestManager.get('/api/admin/posts', params),
  },
  Post: {
    get: (id) => requestManager.get(`/api/admin/posts/${id}`),
    create: (params) => requestManager.post('/api/admin/posts', params),
    delete: (id) => requestManager.delete(`/api/admin/posts/${id}`),
    update: (params) => requestManager.put(`/api/admin/posts/${params.id}`, params),
  },
  BlogPosts: {
    get: (params) => requestManager.get('/api/admin/blog_posts', params),
  },
  BlogPost: {
    get: (id) => requestManager.get(`/api/admin/blog_posts/${id}`),
  },
  Customers: {
    get: (params) => requestManager.get('/api/v2/owner/customers', params),
  },
  CustomerOrders: {
    get: (customerId, params) => requestManager.get(`/api/v2/owner/customer/${customerId}/orders`, params),
  },
  Feedbacks: {
    get: (customerId, params) => requestManager.get(`/api/v2/owner/customer/${customerId}/feedbacks`, params),
  },
  Orders: {
    get: (networkId, params) => requestManager.get(`/api/v2/owner/networks/${networkId}/orders`, params),
  },
  Order: {
    get: (networkId, id) => requestManager.get(`/api/v2/owner/networks/${networkId}/orders/${id}`),
  },
  PasswordReset: {
    create: (params) => requestManager.post('/api/v2/owner/password_reset', params),
    update: (params) => requestManager.put('/api/v2/owner/password_reset', params),
  },
  BaristaPasswordReset: {
    update: (params) => requestManager.put('/api/v2/barista/password_reset', params)
  },
  EmailConfirmation: {
    create: (token) => requestManager.post('/api/v2/owner/confirmation', token),
  },
  Users: {
    create: (params) => requestManager.post('/api/v2/owner/email_registrations', params),
    createPhone: (params) => requestManager.post('/api/v2/owner/phone_registrations', params),
    get: (params) => requestManager.get('/api/admin/users', params),
  },
  NetworkOwner: {
    create: (params) => requestManager.post('/api/v2/owner/owners', params)
  },
  Profiles: {
    get: () => requestManager.get('/api/v2/owner/profile'),
    update: (params) => requestManager.put('/api/v2/owner/profile', params)
  },
  ContactUs: {
    create: (params) => requestManager.post('/api/contact_us', params),
  },
  Payments: {
    get: (params) => requestManager.get('/api/admin/payments', params),
    update: (id) => requestManager.put(`/api/admin/payments/${id}`),
    status: (id) => requestManager.get(`/api/admin/payments/${id}`),
  },
  Notifications: {
    get: (networkId, userId, params) =>
      requestManager.get(`/api/v2/owners/${userId}/notifications`, params),
    openAll: (networkId, userId) =>
      requestManager.post(`/api/v2/owners/${userId}/notifications/open_all`),
    bulkDelete: (networkId, userId, params) =>
      requestManager.post(`/api/v2/owners/${userId}/notifications/bulk_destroy`, params),
    openOne: (networkId, userId, id, params) =>
      requestManager.post(`/api/v2/owners/${userId}/notifications/${id}/open`, params),
  },
  Subscriptions: {
    get: (networkId, userId, params) =>
      requestManager.get(`/api/v2/owners/${userId}/subscriptions`, params),
    subscribe: (networkId, userId, id) =>
      requestManager.put(`/api/v2/owners/${userId}/notifications/${id}/subscribe`),
    unsubscribe: (networkId, userId, id, params) =>
      requestManager.put(`/api/v2/owners/${userId}/notifications/unsubscribe/${id}`, params),
  },
  Statistics: {
    CashTurnover: {
      get: (networkId, params) => requestManager.get(`/api/v2/owner/networks/${networkId}/statistics/cash_turnovers`, params),
    },

    TotalOrders: {
      get: (networkId, params) => requestManager.get(`/api/v2/owner/networks/${networkId}/statistics/total_orders`, params),
    },

    SelesByCategory: {
      get: (networkId, params) => requestManager.get(`/api/v2/owner/networks/${networkId}/statistics/sales`, params),
    },

    BaristaEfficiencies: {
      get: (networkId, params) =>
        requestManager.get(`/api/v2/owner/networks/${networkId}/statistics/barista_efficiencies`, params),
    },

    VendorComparison: {
      get: (networkId, params) =>
        requestManager.get(`/api/v2/owner/networks/${networkId}/statistics/vendor_comparsions`, params),
    },
  },

  BankAccounts: {
    get: (networkId) => requestManager.get(`/api/v2/owner/networks/${networkId}/bank_accounts`),
  },

  BankAccount: {
    create: (networkId, params) => requestManager.post(`/api/v2/owner/networks/${networkId}/bank_accounts`, params),
    delete: (networkId, id) => requestManager.delete(`/api/v2/owner/networks/${networkId}/bank_accounts/${id}`),
    update: (networkId, id, params) => requestManager.put(`/api/v2/owner/networks/${networkId}/bank_accounts/${id}`, params),
  },

  Withdrawals: {
    get: (networkId, params) => requestManager.get(`/api/v2/owner/networks/${networkId}/withdrawals`, params),
    create: (networkId, params) => requestManager.post(`/api/v2/owner/networks/${networkId}/withdrawals`, params)
  },

  Withdrawal: {
    get: (networkId, id) => requestManager.get(`/api/v2/owner/networks/${networkId}/withdrawals/${id}`)
  },

  SmsCodes: {
    requestCode: (params) => requestManager.post('/api/v2/owner/confirmation_codes', params),
  }
}
