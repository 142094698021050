export const CUSTOMERS_GET_REQUEST = 'CUSTOMERS_GET_REQUEST'
export const CUSTOMERS_GET_SUCCESS = 'CUSTOMERS_GET_SUCCESS'
export const CUSTOMERS_GET_FAILURE = 'CUSTOMERS_GET_FAILURE'

export const customersGetRequest = (payload) => ({
  type: CUSTOMERS_GET_REQUEST,
  payload,
})

export const customersGetSuccess = (payload) => ({
  type: CUSTOMERS_GET_SUCCESS,
  payload,
})

export const customersGetFailure = (error) => ({
  type: CUSTOMERS_GET_FAILURE,
  error,
})
