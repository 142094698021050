export const CUSTOMER_FEEDBACKS_GET_REQUEST = 'CUSTOMER_FEEDBACKS_GET_REQUEST'
export const CUSTOMER_FEEDBACKS_GET_SUCCESS = 'CUSTOMER_FEEDBACKS_GET_SUCCESS'
export const CUSTOMER_FEEDBACKS_GET_FAILURE = 'CUSTOMER_FEEDBACKS_GET_FAILURE'

export const customerFeedbacksGetRequest = (payload) => ({
  type: CUSTOMER_FEEDBACKS_GET_REQUEST,
  payload,
})

export const customerFeedbacksGetSuccess = (payload) => ({
  type: CUSTOMER_FEEDBACKS_GET_SUCCESS,
  payload,
})

export const customerFeedbacksGetFailure = (error) => ({
  type: CUSTOMER_FEEDBACKS_GET_FAILURE,
  error,
})
