import React from 'react'
import { useTranslation } from 'react-i18next'
import { isMobile } from 'react-device-detect'

import './styles.scss'
import VendorFeedbackModal from './VendorFeedbackModal/VendorFeedbackModal'
import { Pagination, Text } from '../UIkit'
import CustomerFeedbacksModal from './CustomerFeedbacksModal/CustomerFeedbacksModal'
import OrderInfoModal from '../Orders/Modal/OrderInfoModal'
import CustomersSection from './CustomersSection'

const CustomersPage = ({ onGetCustomerFeedbacks, customerOrders, modals, pagination }) => {
  const { t } = useTranslation()

  return (
    <div className='customers'>
      <Text style='customers__title' variant={isMobile ? 'subheading' : 'h3-medium'}>{t('customers:title')}</Text>

      <CustomersSection
        onOpenCustomerFeedbacks={modals.onOpenCustomerFeedbacks}
        onOpenVendorFeedback={modals.onOpenVendorFeedback}
        onViewOrders={modals.onViewOrders}
      />

      <VendorFeedbackModal
        customer={modals.modalProps.customer}
        isShown={modals.modal === 'vendorFeedback'}
        onClose={modals.onClose}
      />

      <CustomerFeedbacksModal
        onGetCustomerFeedbacks={onGetCustomerFeedbacks}
        customer={modals.modalProps.customer}
        isShown={modals.modal === 'customerFeedbacks'}
        onClose={modals.onClose}
      />

      <OrderInfoModal
        isPrevActionDisabled={!customerOrders.isPrevOrder}
        isNextActionDisabled={!customerOrders.isNextOrder}
        onPrevAction={customerOrders.onPrevOrder}
        onNextAction={customerOrders.onNextOrder}
        isPrevAction
        isNextAction
        order={customerOrders.order}
        fetching={!customerOrders.orderFetched}
        isShown={modals.modal === 'customerOrders'}
        closeModal={modals.onClose}
      />

      <Pagination {...pagination} />
    </div>
  )
}

export default CustomersPage
